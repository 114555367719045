:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;


  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #7e2cc5;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --brand-primary-color: #5A2DBB;
  --brand-secondary-color: #F45B69;
  --link-color: rgb(69, 21, 97);
  --background-color: #9A94BC;  
  --fonts-header:  'Work Sans', sans-serif;
	--fonts: 'Comfortaa', cursive;
  --text-dark-color: #4f0e87;
	--text-light-color: white;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.welcome {
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 1200px;
  transform: translate(-50%, -50%);
  position: fixed;
}

a {
  font-weight: 500;
  color: var(--text-dark-color);
  text-decoration: inherit;
}
a:hover {
  color: var(--background-color);
}

body {
  margin: 0;
  display: flex;
  min-width: 320px;
  background-color: #7e2cc5;
}

.socials img {
  width:200px;
  transition: transform .3s;
}
.socials img:hover {
  transform: scale(1.2); 
}

.logo img {
  width:200px
}

.logo {
  position: relative;
  display: inline-block;
  margin: 1rem;
}
.logo .img-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.logo:hover .img-top {
  display: inline;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  margin: 0.5rem;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.header {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  background-color: #f9f9f9;
  box-shadow: 0px 7px var(--brand-primary-color);


}

.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header li {
  display: inline;
  padding-right: 8px;
  position: relative;
}


.is-sticky {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  /* background-color: red !important; */
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}


/*content boxes*/ 
.box-container {

	padding: 1rem 1rem;
	display: flex;
  flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	justify-content: center;
 overflow: hidden;
}

.box-about {
	background-color: white;
  color: var(--text-dark-color);
  display: flex;
  flex-direction: column;
  margin: auto;
	box-shadow: 5px 10px var(--brand-primary-color);
	overflow: hidden;
	text-overflow: ellipsis; 
	margin-bottom: 2rem;
  max-width: 1200px;
  width: 80%;
  padding: 1rem;
}

.box-partner {
	background-color: white;
  color: var(--text-dark-color);
  display: flex;
  margin: auto;
	max-width: 600px;
	box-shadow: 5px 10px var(--brand-primary-color);
	overflow: hidden;
	text-overflow: ellipsis; 
	margin-bottom: 100px;
  width: 100%;
  padding: 2rem;

}

.box-images {
	background-color: white;
  color: var(--text-dark-color);
  display: flex;
  flex-direction: column;
	box-shadow: 5px 10px var(--brand-primary-color);
	overflow: hidden;
	text-overflow: ellipsis; 
  min-width: 600px;
  width: 80vw;
  max-width: 1400px;
}


.box {
	background-color: white;
  color: var(--text-dark-color);
  display: flex;
	max-width: 600px;
	box-shadow: 5px 10px var(--brand-primary-color);
	overflow: hidden;
	text-overflow: ellipsis; 
	margin-bottom: 100px;
  width: 800px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
  position: relative;
}

.box-review {
	background-color: white;
  color: var(--text-dark-color);
  display: flex;
  flex-direction: column;
	max-width: 600px;
	box-shadow: 5px 10px var(--brand-primary-color);
	overflow: hidden;
	text-overflow: ellipsis; 
  width: 800px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  position: relative;
}


.boxImg {
  float: left;
  width: 200px;
  height: 200px;
  margin-top: 25px;
  margin-right: 20px;
}

.box .boxLink {
 position: absolute;
 bottom: 5%;
 right: 5%;
}
.box.partner {
  height: 200px;
}
.box-img {
	float: right;
	width: 550px;
	height: 550px;
	background: var(--brand-primary-color);
	clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
	shape-outside: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
	margin-left: 50px;
}
  
.box-img img {
	max-height: 550px;
	max-width: 550px;
}

.about-text {
  text-align: left;
}

@media (max-width: 480px) {
  .body {
    padding: 0px;
  }
  .welcome {
    width: 100%;
  }
  .logo {
    margin: 0;
  }
  .logo img {
    width: 100px;
  }
  .box {
    display: block;
    margin-bottom: 100px;
  }
  .box-images {
    flex-direction: column;
    min-width: 10px;
    width: none;
    max-width: none;
  }
  .box-partner {
    flex-direction: column;
    padding: 1rem;
    width: 80%;
  }
  .boxImg {
    float: none;
  }
  .about-text {
    text-align: center;
  }
  .socials img {
    width: 100px;
  }
  .box {
    width: 80vw;
    padding-bottom: 1rem;
  }
  .box .boxLink {
    position: relative;
  }
}